<template>
  <v-container>
    <v-timeline align-top>
      <v-timeline-item
        v-for="(item, i) in items"
        :color="item.color"
        :icon="item.icon"
        :key="i"
        :left="item.left"
        :right="item.right"
        v-if="!item.hide"
        fill-dot
      >
        <v-card dark :color="item.color">
          
          <v-card-title class="title">{{$t(item.titleId)}}</v-card-title>


          <v-container class="white text--primary">
            <v-layout>
              <v-flex xs10 class="text-sm-left">{{$t(item.textId)}}
              </v-flex>
              <v-flex xs2>
                <v-btn icon @click="reverseShowHide" v-if="item.button">
                  <v-icon x-large color="primary">notifications</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      items: [
        {
          color: 'green lighten-1',
          icon: 'cloud_upload',
          textId: 'about.timeline_add_reservation',
          titleId: 'about.timeline_host_title',
          left: false,
          right: true,
          button: false
        },
        {
          color: 'primary',
          icon: 'contact_mail',
          textId: 'about.timeline_upload_id',
          titleId: 'about.timeline_guest_title_remote',
          left: true,
          right: false,
          button: false
        },
        {
          color: 'green lighten-1',
          icon: 'verified_user',
          textId: 'about.timeline_verify_id',
          titleId: 'about.timeline_host_title',
          left: false,
          right: true,
          button: false
        },
        {
          color: 'primary',
          icon: 'directions',
          textId: 'about.timeline_guidebook',
          titleId: 'about.timeline_guest_title_remote',
          left: true,
          right: false,
          button: false
        },
        {
          color: 'primary',
          icon: 'supervisor_account',
          textId: 'about.timeline_match_id',
          titleId: 'about.timeline_guest_title_site',
          left: true,
          right: false,
          button: true
        },
        {
          color: 'green lighten-1',
          icon: 'verified_user',
          textId: 'about.timeline_host_verify',
          titleId: 'about.timeline_host_title',
          left: false,
          right: true,
          hide: true,
          button: false
        },
        {
          color: 'primary',
          icon: 'vpn_key',
          textId: 'about.timeline_key',
          titleId: 'about.timeline_guest_title_site',
          left: true,
          right: false,
          button: false
        },
      ]
    }
  },
  
  methods: {
    reverseShowHide () {
      this.items[5].hide = !this.items[5].hide
    },
  },
}
</script>